.next-episode-button {
  color: #828181;
  transition: color 0.1s;

  &:hover {
    color: #c0c0c0;
  }

  &:disabled {
    color: #2b2b2b;
    cursor: default;
  }

  svg {
    width: 100%;
    height: 22px;
  }
}
