[data-dvr-controls] {
  display: none;
}

.media-control[data-media-control] {
  .media-control-layer[data-controls] {
    button.media-control-button[data-hd-indicator].enabled {
      display: none;

      @media(min-width: 576px) {
        display: block;
      }
    }
  }
}

@media(min-width: 360px) {
  [data-dvr-controls] {
    display: inline-block;
  }
}
