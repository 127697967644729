.video-js {
  .vjs-big-play-button {
    display: none !important;
  }

  .vjs-control-bar {
    background-color: transparent !important;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.9)) !important;

    .vjs-control.vjs-current-time {
      display: block;
      padding: 0;
    }

    .vjs-time-control.vjs-time-divider {
      display: block;
    }

    .vjs-control.vjs-duration {
      display: block;
      padding: 0;
    }

    .vjs-play-progress {
      background-color: #ffff4d;
    }
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: #16151a !important;
  }

  .vjs-menu {
    li.vjs-menu-item {
      background-color: #16151a;

      &:hover {
        background-color: #16151a;
        color: #ffff4d;
      }
    }
  }

  .vjs-menu li.vjs-selected,
  .vjs-menu li.vjs-selected:focus {
    background-color: #ffff4d;
    color: #111014;

    &:hover {
      background-color: #ffff4d;
      color: #111014;
    }
  }

  .vjs-button.episode-switch-button {
    .vjs-icon-placeholder.episode-icon-prev {
      &::before {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        content: "\25B6\2502";
        font-size: 1.2em;
      }
    }
  }
}
