@import "@/styles/new-variables";

:root {
  font-size: 16px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

// a,
// input,
// button,
// textarea,
// [tabindex] {
//   &:not([data-focus-visible-added]) {
//     outline: none;
//   }
// }

button:focus-visible,
a:focus-visible {
  outline: 1px solid $primary;
}

body {
  font-family: $base-font;
  box-sizing: border-box;
  color: $white;
  background-color: $black;
  overflow-x: hidden !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  color: inherit;
  border: none;
  cursor: pointer;
  background: none;
  user-select: none;
  font: inherit;
}

input {
  font-family: inherit;
  background: none;
  border: none;
}

/* Hide the spinner in Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield; /* Firefox-specific */
}

textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 50px #bab9bb inset;
}

.swiper-container {
  display: none;
}

.swiper-container-initialized {
  display: block;
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
::-webkit-scrollbar-thumb {
  background: lighten($dark, $amount: 10);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mainLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: $mobile-header-height;
    background-color: $black;
  }

  @media (min-width: 1200px) {
    .content {
      padding-top: $desktop-header-height;
    }
  }
}

.video-container video::-webkit-media-controls-timeline,
.video-container video::-webkit-media-controls-seek-back-button,
.video-container video::-webkit-media-controls-seek-forward-button {
  display: none;
}

.swiper-wrapper {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out !important; /* You can replace with your desired function */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  height: auto !important;
}

.swiper.home-banner {
  .swiper-slide {
    transition-duration: 700ms;
    transition-timing-function: cubic-bezier(
      0.455,
      0.03,
      0.515,
      0.955
    ) !important; /* You can replace with your desired function */
  }

  .swiper-slide {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition-property: z-index, background-color;
      transition-duration: 0.7s;
    }

    &.swiper-slide-prev {
      &::after {
        background-color: transparent;
      }
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      &::after {
        background-color: rgba(15, 15, 18, 0.5);
        z-index: 2;
      }
    }
  }
}
