@import '@/styles/variables';

.audio_track_selector[data-audio-track-selector] {
  float: right;
  position: relative;
  height: 100%;

  button {
    background-color: transparent;
    color: #ffffff;
    font-family: Roboto, "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    border: none;
    font-size: 12px;
    height: 100%;
  }

  &>ul {
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    border-radius: 4px;
    background-color: rgba(28, 28, 28, 0.9);
    white-space: nowrap;
  }

  li {
    font-size: 12px;
    color: #eeeeee;

    a {
      color: $light-dimmed-color-4;
      padding: 5px 18px;
      display: block;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }

    &.current a {
      color: $signature-color;
    }
  }
}
