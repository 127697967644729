@import '@/styles/variables';

.level_selector[data-level-selector] {
  float: right;
  position: relative;
  height: 100%;
  margin-left: 10px;

  button {
    background-color: transparent;
    color: #ffffff;
    font-family: Roboto, "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    border: none;
    font-size: 12px;
    height: 100%;
  }

  &>ul {
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    border-radius: 4px;
    background-color: rgba(28, 28, 28, 0.9);
    white-space: nowrap;
  }

  li {
    font-size: 12px;
    color: #eeeeee;

    &[data-title] {
      background-color: #333333;
      padding: 8px 25px;
    }

    a {
      color: $light-dimmed-color-4;
      padding: 8px 15px;
      display: block;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }

    &.current a {
      color: $signature-color;
    }

  }
}
